import React from 'react';
import {motion} from 'framer-motion';

import ParallaxImage from './ParallaxImage';
import SplitLetter from './SplitLetter';
import MotionTopdown from './MotionTopDown';
import HtmlText from './HtmlText';

export default function TopHero({title, pageSubtitles, image, height="60vh", paddingBottom="30px", children}) {
  return (
    <motion.div initial="initial" animate="animate" exit="exit">
      <ParallaxImage image={image} height={height}>
        <div>
        {
          title &&
          // <MotionTopDown className="text-white whitespace-nowrap text-center">
          //   <h1>{title}</h1>
          // </MotionTopDown>
          <SplitLetter className="text-white whitespace-nowrap text-center" wrapper="h1">
            {title}
          </SplitLetter>
        }
        {
          pageSubtitles &&
          <div className='mt-8 p-5 backdrop-blur-md' style={{background: 'rgba(255, 255, 255, 0.2)', backdropFilter: 'blur(10px)'}}>
            {
              pageSubtitles.map((subtitle, i) => (
                <MotionTopdown key={i} delay={1.8} className="text-white whitespace-nowrap text-center text-2xl">
                  <HtmlText html={subtitle} />
                </MotionTopdown>
              ))
            }
          </div>
        }
        </div>
        {children}
      </ParallaxImage>
      {
        paddingBottom &&
        <div style={{height: paddingBottom}}></div>
      }
    </motion.div>
  )
}
